export const UNITO_APP_URL = process.env.REACT_APP_CONSOLE_BASE_URL || process.env.REACT_APP_MAESTRO_BASE_URL;

// API errors
export const API_ERRORS = {
  SyncConflictError: {
    message: 'Oops, this Mirror already exists in the Unito App.',
  },
  TaskSyncConflictError: {
    message: 'Oops! This card is already synced with the selected board.',
  },
  ForbiddenError: {
    message: 'You are forbidden to access mirrored cards on this board.',
  },
  DefaultCreateError: {
    message:
      'Sorry, something went wrong when creating your Mirror. Please try again or contact mirror-support@unito.io if the problem persists.',
  },
  DefaultError: {
    message: "Oops! It seems like we've lost the link with this card.",
  },
  OverTheMirrorLimitError: {
    message: 'The mirror could not be created. You reached the maximum mirrors allowed by your plan.',
  },
};

export const REQUEST_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

export const MAESTRO_BASE_API = 'api/v1';

export const ID_SEPARATOR = '||';

export const PLAN_FEATURE_UNLIMITED = 'unlimited';

export const PLAN_FEATURES = {
  SUBTASK: 'subtask',
  CUSTOM_FIELD: 'CUSTOM_FIELD',
  MAPPED_FIELDS: 'mapped-fields',
  MAX_MIRROR_SYNCS: 'MAX_MIRROR_SYNCS',
  MAX_ITEMS: 'MAX_ITEMS_KEPT_IN_SYNC',
};

export const PROVIDER_NAMES = {
  GOOGLE_SHEETS: 'googleSheets',
  TRELLO: 'trello',
};

export const FIELD_SETTINGS_DEFAULT = {
  attachments: {
    sync: true,
    displayName: 'Attachments',
    readOnly: false,
    pcd: true,
  },
  customFields: {
    sync: true,
    displayName: 'Custom fields with matching names',
    planFeature: PLAN_FEATURES.CUSTOM_FIELD,
    readOnly: false,
    pcd: false,
  },
  dueDate: {
    sync: true,
    displayName: 'Due dates',
    readOnly: false,
    pcd: true,
  },
  isDueDateComplete: {
    sync: true,
    displayName: 'Due date checkbox completion',
    pcd: true,
    readOnly: false,
  },
  labels: {
    sync: true,
    displayName: 'Labels',
    readOnly: false,
    pcd: true,
  },
  checklists: {
    sync: true,
    displayName: 'Checklists',
    planFeature: PLAN_FEATURES.SUBTASK,
    readOnly: false,
    pcd: false,
  },
  title: {
    sync: true,
    displayName: 'Title (required field)',
    readOnly: true,
    pcd: true,
  },
  comments: {
    sync: true,
    displayName: 'Comments (required field)',
    readOnly: true,
    pcd: true,
  },
  description: {
    sync: true,
    displayName: 'Description (required field)',
    readOnly: true,
    pcd: true,
  },
  checkItemComplete: {
    sync: true,
    displayName: 'Check item complete',
    readOnly: true,
    pcd: true,
    isHiddenFromUsers: true,
  },
};

// From console/api/resources/links/index.js
export const BACKEND_SYNC_STATES = {
  active: { displayName: 'active', type: 'primary' },
  // Legacy state, if the sync was no longer accessible because of disconnection or inacessibility
  disabled: { displayName: 'disabled', type: 'error' },
  // If a link has an invalid provider identity
  disconnected: { displayName: 'disconnected', type: 'error' },
  deleted: { displayName: 'deleted', type: 'error' },
  // If one of the getContainer raised an error
  // The INACCESSIBLE state takes precedence over the DISCONNECTED one
  inaccessible: { displayName: 'inaccessible', type: 'error' },
  // The sync was archived because no more tasks are synced by it
  // Used by syncs of kind taskSync
  archived: { displayName: 'archived', type: 'error' },
  paused: { displayName: 'paused', type: 'error' },
};

export const SYNC_STATE_INPROGRESS = 'inprogress';
export const SYNC_STATES = {
  ...BACKEND_SYNC_STATES,
  // Color for `inprogress` is same as `active`, to not attract attention
  // or suggest it's not working.
  // As far as we know here, it's a regular functional sync, just a young one.
  [SYNC_STATE_INPROGRESS]: { displayName: 'in progress', type: 'primary' },
};

export const FRONT_OF_CARD_BACKEND_REFRESH_PERIOD_MS = 5 * 60 * 1000;

export const UNITO_WORKSPACE_STATUSES = {
  TRIALING: 'trialing',
  RESOLD: 'resold',
  TRIAL_EXPIRED: 'trial-expired',
  PAYING: 'paying',
  DELINQUENT: 'delinquent',
  SUSPENDED: 'suspended',
  CANCELED: 'canceled',
  CHURNED: 'churned',
};

export const BOARD_MEMBER_TYPES = {
  ADMIN: 'admin',
  MEMBER: 'member',
};

export const INTERNAL_SOURCES = {
  MIRROR: 'mirror',
  BOARDSYNC: 'board-sync',
  REPORT: 'report',
};

export const POWERUP_DISPLAY_NAMES = {
  MIRROR: 'Mirror',
  BOARDSYNC: 'Board Sync',
  REPORT: 'Export & Sync',
  UNITOSYNC: 'Unito Sync',
};

export const TASK_COUNT_FETCH_TIMEOUT = 2000;

export const DEFAULT_CONTAINER_TYPE = 'board';

export const DEFAULT_ITEM_TYPE = 'card';

export const ORG_REVIVED_MODAL_TYPES = {
  ADDED_TO_EXPIRED_ORG: 'expired',
  REVIVED_EXPIRED_ORG: 'revived',
};

// Unito production powerup Id for board sync
export const UNITO_BOARD_SYNC_POWERUP_ID = '595e989fa8f137d2af456fd4';

export const POWERUP_CAPABILITIES = {
  AUTHORIZATION_STATUS: 'authorization-status',
  BOARD_BUTTONS: 'board-buttons',
  CARD_BUTTONS: 'card-buttons',
  CARDS_BADGES: 'card-badges',
  CARD_DETAIL_BADGES: 'card-detail-badges',
  CARD_BACK_SECTION: 'card-back-section',
  ON_DISABLE: 'on-disable',
  ON_ENABLE: 'on-enable',
  SHOW_AUTHORIZATION: 'show-authorization',
  SHOW_SETTINGS: 'show-settings',
};

export const MIRROR_CREATION = 'MIRROR_CREATION';
export const SIGNUP_LOGIN = 'SIGNUP_LOGIN';
export const MIRROR_BACK_OF_CARD = 'MIRROR_BACK_OF_CARD';
