import { v4 } from 'uuid';
import { loadSecretSafe } from './trello';
import { REQUEST_METHODS, MAESTRO_BASE_API } from '../consts';
import { RequestAllowedDelayExceeded, TrelloInstanceNotProvided } from './errors';
import { delay } from './helpers';
import { logger } from './logger';
import * as Fetch from './fetch';

const HTTP_NO_CONTENT = 204;
const DEFAULT_FETCH_TIMEOUT = 8000;

const buildRequestOptions = (method, options) => {
  const { body = {}, token } = options;
  const requestOptions = {
    method: method.toUpperCase(),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Unito-Correlation-Id': v4(),
      'Unito-GMT-offset': new Date().getTimezoneOffset() / 60,
    },
    body: method !== REQUEST_METHODS.GET ? JSON.stringify(body) : undefined,
  };

  if (token) {
    requestOptions.headers.Authorization = `Bearer ${token}`;
  }

  return requestOptions;
};

export const processResponse = async (response) => {
  if (response.status === HTTP_NO_CONTENT) {
    return;
  }

  // Reject all responses of content type that are not json
  const contentType = response.headers.get('content-type');
  if (!contentType || !contentType.startsWith('application/json')) {
    const text = await response.text();
    return Promise.reject({
      logMessage: text,
      status: response.status,
      name: response.name,
    });
  }

  return response.json();
};

// if some requests to Maestro take too much time to complete
// some PowerUp capabilities might not load correctly
export const fetchWithTimeout = async (url, requestOptions, timeoutDelay = DEFAULT_FETCH_TIMEOUT) => {
  // Initiate the fetch and delay but don't await them yet, just keep the promise.
  const fetchPromise = fetch(url, requestOptions);

  const delayPromise = delay(timeoutDelay);

  // Wait for whichever promise completes first.
  const resp = await Promise.race([fetchPromise, delayPromise]);
  if (!resp) {
    throw new RequestAllowedDelayExceeded(timeoutDelay, url.pathname);
  }

  return resp;
};

export const callFetch = async (url, requestOptions, timeoutDelay) => {
  // Promise.race used in fetchWithTimeout is not supported in IE
  const fetchFn = timeoutDelay && Promise.race ? fetchWithTimeout : fetch;
  return fetchFn(url, requestOptions, timeoutDelay);
};

// urlSearch passed as parameter to facilitate tests.
export const getQueryParameter = (parameter = '', urlSearch = window.location.search) => {
  const searchParams = new URLSearchParams(urlSearch);
  return searchParams.get(parameter);
};

export const getMaestroEmbedUrl = (params) => {
  const queryString = Object.keys(params)
    .map((name) => `${name}=${encodeURIComponent(params[name])}`)
    .join('&');
  return `${process.env.REACT_APP_MAESTRO_BASE_URL}/api/embed/trello?${queryString}`;
};

export const callMaestro = async (
  endpoint,
  { method = REQUEST_METHODS.GET, baseApi = MAESTRO_BASE_API, options = {}, t } = {},
  timeoutDelay,
) => {
  if (t === undefined) {
    throw new TrelloInstanceNotProvided(
      'callMaestro was not provided a Trello instance, but it is needed for it to work',
    );
  }

  const requestOptions = buildRequestOptions(method, {
    ...options,
    token: (options || {}).token || (await loadSecretSafe('token', t)),
  });
  const url = new URL(encodeURI(`${process.env.REACT_APP_MAESTRO_BASE_URL}/${baseApi}/${endpoint}`));
  Object.entries(options.params || {}).forEach(([key, value]) => {
    url.searchParams.append(key, value);
  });

  let response;
  try {
    response = await Fetch.callFetch(url, requestOptions, timeoutDelay);
    const processResponse = await Fetch.processResponse(response);
    if (!response.ok) {
      throw processResponse;
    }
    return processResponse;
  } catch (e) {
    const correlationId = response?.headers?.get('unito-correlation-id');
    const enrichedError = {
      ...e,
      correlationId,
    };
    const profileId = t.getContext().member;
    const { code, name } = enrichedError;
    logger.error(
      `Error while contacting maestro endpoint ${endpoint} with options ${JSON.stringify(
        options,
        null,
        2,
      )}. Err: ${JSON.stringify(enrichedError)}`,
    );

    if (code === 401) {
      await t.clearSecret('token');
      logger.info(`Cleared secret for profileId ${profileId} with 401 error: ${name}`);
    }

    throw enrichedError;
  }
};
